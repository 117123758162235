import React, { useState, useEffect } from 'react'
import TabbedInterfaceTabs from './TabbedInterfaceTab'
import TabbedInterfaceContent from './TabbedInterfaceContent'

const TabbedInterface = () => {
    const [activeTab, setActiveTab] = useState('tab1')

    const tabs = [
        'Precision Targeting & Customer Segmentation',
        'Predictive Analytics & Insights',
        'Content Creation & Optimisation',
        'Real-Time Campaign Monitoring & Management',
        'Social Media Insights & Engagement',
        'Customer Journey Mapping',
        'Automated Marketing Workflows',
        'Sentiment Analysis & Brand Monitoring',
    ]

    const content = [
        'Use AI to accurately identify and target your ideal customer segments, creating personalised campaigns that truly resonate.',
        'Anticipate market trends and customer behaviours with AI-driven predictive analytics, optimising strategies for maximum impact and ROI.',
        'Enhance your content strategy with AI tools that create and optimise engaging content tailored to your brand and audience.',
        'Manage your marketing campaigns in real time with AI-driven dashboards, gaining instant performance insights and recommendations.',
        'Amplify your social media presence with AI tools that optimise posting schedules, analyse engagement, and identify trending topics.',
        'Improve customer experiences with AI-driven journey mapping, revealing valuable insights to tailor your approach and boost satisfaction.',
        'Streamline operations with AI-automated workflows, optimising your marketing funnel for efficiency and effectiveness.',
        'Protect your brand with AI-powered sentiment analysis, monitoring public perception and addressing issues promptly.',
    ]

    useEffect(() => {
        const imageUrls = tabs.map((_, index) => `/images/tab${index + 1}-image.jpg`)
        imageUrls.forEach((url) => {
            const img = new Image()
            img.src = url
        })
    }, [])

    return (
        <div className="flex flex-col lg:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px]">
            <div className="flex-1 flex flex-wrap lg:flex-nowrap lg:flex-col lg:max-w-[510px]">
                {tabs.map((heading, index) => (
                    <TabbedInterfaceTabs key={index} tabNumber={`${index + 1}`} heading={heading} activeTab={activeTab} setActiveTab={setActiveTab} />
                ))}
            </div>

            <div className="flex-1 border-t-2 lg:border-l-2 lg:border-t-0 border-kaz-blue border-opacity-40 md:min-w-[510px] lg:min-w-[702px]">
                {content.map((content, index) => (
                    <TabbedInterfaceContent
                        key={index}
                        tabNumber={index + 1}
                        content={content}
                        isActive={activeTab === `tab${index + 1}`}
                    />
                ))}
            </div>
        </div>
    )
}

export default TabbedInterface