import React, { createContext, useContext, useState, useCallback } from 'react'
import { KIST_URL } from '../util/env_defaults'

const JobsContext = createContext()

export function JobsProvider({ children }) {
  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [initialized, setInitialized] = useState(false)

  const fetchJobs = useCallback(async () => {
    if (initialized) return;
    
    setLoading(true)
    try {
      const response = await fetch(`${KIST_URL}/jobs`)
      if (!response.ok) {
        throw new Error('Failed to fetch jobs')
      }
      const data = await response.json()
      setJobs(data)
      setError(null)
      setInitialized(true)
    } catch (err) {
      console.error('Error fetching jobs:', err)
      setError('Failed to load jobs. Please try again later.')
    } finally {
      setLoading(false)
    }
  }, [initialized])

  const refreshJobs = useCallback(async () => {
    setLoading(true)
    try {
      const response = await fetch(`${KIST_URL}/jobs`)
      if (!response.ok) {
        throw new Error('Failed to fetch jobs')
      }
      const data = await response.json()
      setJobs(data)
      setError(null)
    } catch (err) {
      console.error('Error fetching jobs:', err)
      setError('Failed to load jobs. Please try again later.')
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <JobsContext.Provider value={{ jobs, loading, error, fetchJobs, refreshJobs }}>
      {children}
    </JobsContext.Provider>
  )
}

export function useJobs() {
  const context = useContext(JobsContext)
  if (context === undefined) {
    throw new Error('useJobs must be used within a JobsProvider')
  }
  return context
} 