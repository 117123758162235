import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Perform logout
    logout();
    // Redirect to login page
    navigate('/', { replace: true });
  }, [logout, navigate]);

  // Return null since this is just a functional component that handles logout
  return null;
}

export default Logout;
