import React, { useState } from 'react'
import axios from 'axios'
import SmoothScroll from 'smooth-scroll'
import { object, string } from 'yup'
import FormField from './FormField'
import ContactUsShape from './ContactUsShape'

const ContactUs = () => {
    const submitFormUrl = process.env.REACT_APP_FORM_SUBMISSION_URL
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isFormUnsuccessful, setIsFormUnsuccessful] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        message: ''
    })
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        number: '',
        message: ''
    })

    const formSchema = object({
        name: string().required('Your full name is required'),
        number: string().matches(/^\d+(\s\d+)*$/, 'Your phone number must contain only digits').required('Your phone number is required'),
        email: string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email must be valid format e.g. email@email.com').required('Your email address is required').email('Email must be valid format e.g. email@email.com'),
        message: string().required('An inquiry message is required')
    })
    
    const submitAutoScroll = () => {
        const width = window.innerWidth
        if(width < 768) {
            const element = document.getElementById('contact-us-message')
            const scroll = new SmoothScroll()
            scroll.animateScroll(element, null, {
                speed: 1000,
                speedAsDuration: true,
                easing: 'easeInOutCubic',
            })
        }
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await formSchema.validate(formData, { abortEarly: false })
            const response = await axios.post(submitFormUrl, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            setFormData({
                name: '',
                email: '',
                number: '',
                message: ''
            })
            setFormErrors({
                name: '',
                email: '',
                number: '',
                message: ''
            })
            setIsFormUnsuccessful(false)
            setFormSubmitted(true)
            submitAutoScroll()
        } catch (error) {
            if (error.name === 'ValidationError') {
                const errors = {}
                error.inner.forEach((err) => {
                    errors[err.path] = err.message
                })
                setFormErrors(errors)
                setIsFormUnsuccessful(false)
                setFormSubmitted(false)
            } else {
                console.error('Error creating post')
                setFormSubmitted(false)
                setIsFormUnsuccessful(true)
                submitAutoScroll()
            }
        }
    }

    return (
        <section className="container relative mb-[90px] md:mb-[120px]" id="contact-us">
            <h1 className='h1-large text-center mb-10'>Contact Us</h1>
            <div className='relative flex flex-col md:flex-row items-center bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px]'>
                <div className='flex-1 flex flex-col items-center w-full border-b-2 md:border-b-0 md:border-r-2 border-solid border-kaz-blue border-opacity-40'>
                    <form onSubmit={handleSubmit} className='flex flex-col items-center w-full p-7 sm:p-14'>
                        <FormField
                            fieldName='name'
                            fieldLabel='Full Name'
                            value={formData.name}
                            handleChange={handleChange}
                            errors={formErrors.name}
                        />
                        <FormField
                            fieldName='email'
                            fieldLabel='Email Address'
                            value={formData.email}
                            handleChange={handleChange}
                            errors={formErrors.email}
                            type={'email'}
                        />
                        <FormField
                            fieldName='number'
                            fieldLabel='Mobile Number'
                            value={formData.number}
                            handleChange={handleChange}
                            errors={formErrors.number}
                            type={'tel'}
                        />
                        <FormField
                            fieldName='message'
                            fieldLabel='Message'
                            value={formData.message}
                            handleChange={handleChange}
                            errors={formErrors.message}
                        />
                        <button type="submit" className='btn btn-primary md:mt-5'>Submit Inquiry</button>
                    </form>
                </div>
                <div id='contact-us-message' className='flex-1 w-full h-full min-h-[400px] flex flex-col justify-center'>
                    {!formSubmitted ? (
                        isFormUnsuccessful ? (
                            <div className='flex flex-col animate-fadeIn'>
                                <div className='w-fit mx-auto p-14'>
                                    <h2 className='h2 pb-5'>Something Went Wrong</h2>
                                    <p className='p'>Your inquiry was not successful, please try again later.</p>
                                </div>
                            </div>
                        ) : (
                            <ContactUsShape />
                        )
                    ) : (
                        <div className='flex flex-col animate-fadeIn'>
                            <div className='w-fit mx-auto p-14'>
                                <h2 className='h2 pb-5'>Inquiry Submitted</h2>
                                <p className='p'>Thank you for your inquiry with KazacosAI. We have received your message and we will get back to you soon.</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}


export default ContactUs
