import React from 'react'
import FinancialServiceBox from './FinancialServiceBox'

const FinancialServices = () => {
    return (
        <section className="container relative mb-[60px] md:mb-[90px]" id="financial-services">
            <h2 className='h2 mb-6 text-center xs:text-left xs:pl-12'>AI-Powered Financial Services</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-[20px] align-center'>
                <FinancialServiceBox
                    heading='AI Policy Reviewers & Quiz Makers'
                    content='Ensure compliance and improve training with AI tools that
                             analyse policies and create custom quizzes,
                             keeping your team informed and compliant.'
                />
                <FinancialServiceBox
                    heading='AI-Assisted Risk Management'
                    content='Identify and mitigate risks with precision using AI-driven tools that
                             assess threats and implement strategic measures, safeguarding your assets.'
                />
                <FinancialServiceBox
                    heading='AI-Assisted Website Review & Comparator'
                    content='Enhance your online presence with AI evaluations against industry benchmarks,
                             providing actionable insights to improve user experience and engagement.'
                />
                <FinancialServiceBox
                    heading='AI Assistance for Trustees'
                    content='Empower trustees with AI tools for decision-making, compliance,
                             and performance evaluation, ensuring effective and confident management.'
                />
            </div>
        </section>
    )
}

export default FinancialServices
