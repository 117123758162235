import React, { createContext, useContext, useState, useCallback } from 'react'
import axios from 'axios'
import { KIST_URL } from '../util/env_defaults'
import { useAuth } from './AuthContext'

const ApplicantsContext = createContext()

export function ApplicantsProvider({ children }) {
  const [applicants, setApplicants] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const { token } = useAuth();

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  }

  const fetchApplicants = useCallback(async () => {
    if (initialized) return;
    
    setLoading(true)
    try {
      if (!token) {
        throw new Error('No authentication token found')
      }

      const response = await axios.get(`${KIST_URL}/jobs/applicants`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      
      setApplicants(response.data)
      setError(null)
      setInitialized(true)
    } catch (err) {
      console.error('Error fetching applicants:', err)
      setError(err.response?.data?.message || 'Failed to load applicants. Please try again later.')
    } finally {
      setLoading(false)
    }
  }, [initialized, token])

  const refreshApplicants = useCallback(async () => {
    setLoading(true)
    try {
      if (!token) {
        throw new Error('No authentication token found')
      }

      const response = await axios.get(`${KIST_URL}/jobs/applicants`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      })
      
      setApplicants(response.data)
      setError(null)
    } catch (err) {
      console.error('Error refreshing applicants:', err)
      setError(err.response?.data?.message || 'Failed to refresh applicants. Please try again later.')
    } finally {
      setLoading(false)
    }
  }, [])

  const getApplicantsByJobId = useCallback((jobId) => {
    return applicants.filter(applicant => applicant.jobId === jobId)
  }, [applicants])

  const getApplicantById = useCallback((id) => {
    return applicants.find(applicant => applicant.id === id)
  }, [applicants])

  return (
    <ApplicantsContext.Provider 
      value={{ 
        applicants, 
        loading, 
        error, 
        fetchApplicants, 
        refreshApplicants,
        getApplicantsByJobId,
        getApplicantById
      }}
    >
      {children}
    </ApplicantsContext.Provider>
  )
}

export function useApplicants() {
  const context = useContext(ApplicantsContext)
  if (context === undefined) {
    throw new Error('useApplicants must be used within an ApplicantsProvider')
  }
  return context
} 