import React from 'react'

const ArticleItem = ({ date, heading, author, imageFile, alt, link }) => {
    return link ? (
        <a className='flex flex-col sm:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] p-7 sm:p-10 gap-5 transition-[background-color] duration-300 ease-in-out hover:bg-kaz-blue hover:bg-opacity-[0.15]' href={link} target="_blank" rel="noreferrer">
            <div className='flex-1 flex flex-col justify-between gap-5'>
                <p className='h4 gradient-text w-fit'>{date}</p>
                <h3 className='h3 font-semibold leading-snug'>{heading}</h3>
                <p className='h4 font-medium tracking-wide hidden sm:block'>By {author}</p>
            </div>
            <div className='w-full h-full sm:w-1/3 sm:h-[180px]'>
                <img src={`/images/${imageFile}`} alt={`${alt}`} className='rounded-[32px] w-full h-full max-h-[300px] sm:max-h-none object-cover'></img>
            </div>
            <p className='h4 font-medium tracking-wider sm:tracking-wide sm:hidden'>By {author}</p>
        </a>
    ) : (
        <div className='flex flex-col sm:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] p-7 sm:p-10 gap-5'>
            <div className='flex-1 flex flex-col justify-between gap-5'>
                <p className='h4 gradient-text w-fit'>{date}</p>
                <h3 className='h3 font-semibold leading-snug'>{heading}</h3>
                <p className='h4 font-medium tracking-wide hidden sm:block'>By {author}</p>
            </div>
            <div className='w-full h-full sm:w-1/3 sm:h-[180px]'>
                <img src={`/images/${imageFile}`} alt={`${alt}`} className='rounded-[32px] w-full h-full max-h-[300px] sm:max-h-none object-cover'></img>
            </div>
            <p className='h4 font-medium tracking-wider sm:tracking-wide sm:hidden'>By {author}</p>
        </div>
    )
}

export default ArticleItem
