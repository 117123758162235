import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import GlowBackground from '../components/GlowBackground';

function NotFound() {
  return (
    <div className='flex flex-col items-center py-[20px] min-h-screen overflow-hidden'>
      <GlowBackground />
      <Navbar />
      
      <main className="container relative mb-[90px] md:mb-[120px]">
        <div className='relative bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mt-12'>
          <div className='p-7 sm:p-14 text-center'>
            <h1 className="h1-large mb-6">404</h1>
            <h2 className="text-2xl text-white mb-8">Page Not Found</h2>
            <p className="text-gray-300 mb-10">
              The page you're looking for doesn't exist or has been moved.
            </p>
            <Link 
              to="/" 
              className="btn btn-primary"
            >
              Return Home
            </Link>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default NotFound; 