import React from 'react'
import TabbedInterface from './TabbedInterface'

const MarketingServices = () => {
    return (
        <section className="container relative mb-[60px] md:mb-[90px]" id="marketing-services">
            <h2 className='h2 mb-6 text-center xs:text-left xs:pl-12'>AI-Driven Marketing Services</h2>
            <TabbedInterface />
        </section>
    )
}

export default MarketingServices