import React from 'react'

const TabbedInterfaceTabs = ({ tabNumber, heading, activeTab, setActiveTab }) => {
    const handleTabClick = (tab) => {
        setActiveTab(tab)
    }
    
    return (
        <button
            className={`cursor-pointer transition-[background-color] duration-300 ease-out px-[24px] sm:px-[20px] py-[16px] lg:px-[28px] lg:py-[20px] text-center lg:text-left first:rounded-t-[38px] sm:first:rounded-tr-[0px] sm:[&:nth-child(4)]:rounded-tr-[38px] lg:last:rounded-bl-[38px] lg:[&:nth-child(4)]:rounded-tr-[0] w-full sm:w-1/4 lg:w-full lg:h-full hover:bg-kaz-blue hover:bg-opacity-15 ${activeTab === `tab${tabNumber}` ? 'active' : ''}`}
            onClick={() => handleTabClick(`tab${tabNumber}`)}
        >
            <h4 className={`h4 text-center lg:text-left ${activeTab === `tab${tabNumber}` ? 'gradient-text animate-textFadeOut' : ''}`}>{heading}</h4>
        </button>
    )
}

export default TabbedInterfaceTabs
