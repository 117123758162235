import React, { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GlowBackground from '../components/GlowBackground'
import FormField from '../components/FormField'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { object, string, mixed } from 'yup'
import { useJobs } from '../contexts/JobsContext'
import ApplicationViewer from '../components/ApplicationViewer'
import { KIST_URL, RECAPTCHA_SITE_KEY } from '../util/env_defaults'

function JobApplication() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { jobs, loading, error, fetchJobs } = useJobs()
  const job = jobs.find(job => job.id === id)
  const recaptchaRef = useRef()
  const fetchInitiated = useRef(false)

  useEffect(() => {
    if (!fetchInitiated.current) {
      fetchInitiated.current = true
      fetchJobs()
    }
  }, [fetchJobs])

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isFormUnsuccessful, setIsFormUnsuccessful] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [captchaError, setCaptchaError] = useState('')
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    linkedin: '',
    github: '',
    portfolio: '',
    experience: '',
    coverLetter: '',
    resume: null,
    captchaToken: '',
    salaryExpectation: '',
  })
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    linkedin: '',
    github: '',
    portfolio: '',
    experience: '',
    coverLetter: '',
    resume: '',
    salaryExpectation: '',
  })

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

  const isProd = process.env.NODE_ENV === 'production'

  const formSchema = object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email must be valid format e.g. email@email.com')
      .required('Email address is required'),
    phone: string()
      .matches(/^[+(\s.\-/\d)]{5,30}$/, 'Please enter a valid phone number')
      .required('Phone number is required'),
    location: string().required('Current location is required'),
    experience: string().required('Relevant experience is required'),
    coverLetter: string().required('Cover letter is required'),
    resume: mixed()
      .required('Resume is required')
      .test('fileSize', 'File size must be less than 5MB', value => {
        if (!value) return true;
        return value.size <= MAX_FILE_SIZE;
      })
      .test('fileType', 'Only PDF files are accepted', value => {
        if (!value) return true;
        return value.type === 'application/pdf';
      }),
    captchaToken: isProd ? string().nullable().required('Please complete the captcha') : string().nullable(),
    portfolio: string(),
    salaryExpectation: string(),
  })

  const [submittedApplication, setSubmittedApplication] = useState(null)

  if (loading) {
    return (
      <div className='flex flex-col items-center py-[20px] min-h-screen'>
        <GlowBackground />
        <Navbar />
        <div className="container text-center text-white py-8">
          Loading job details...
        </div>
        <Footer />
      </div>
    )
  }

  if (error || !job) {
    return (
      <div className='flex flex-col items-center py-[20px] min-h-screen'>
        <GlowBackground />
        <Navbar />
        <div className="container text-center text-white py-8">
          <h2 className="text-xl mb-4">Job Not Found</h2>
          <button 
            onClick={() => navigate('/jobs')}
            className="text-kaz-blue hover:text-white transition-colors duration-300 px-4 py-2 rounded-full border-2 border-kaz-blue border-opacity-40 hover:border-opacity-100 hover:bg-kaz-blue hover:bg-opacity-25"
          >
            Return to Jobs
          </button>
        </div>
        <Footer />
      </div>
    )
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
    // Clear error when user starts typing
    setFormErrors(prev => ({
      ...prev,
      [name]: ''
    }))
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    
    // Clear previous file error
    setFormErrors(prev => ({ ...prev, resume: '' }));

    if (!file) {
      setFormData(prev => ({
        ...prev,
        resume: null
      }))
      return
    }

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      setFormErrors(prev => ({
        ...prev,
        resume: 'File size must be less than 5MB'
      }));
      e.target.value = ''; // Reset file input
      return;
    }

    // Validate file type
    if (file.type !== 'application/pdf') {
      setFormErrors(prev => ({
        ...prev,
        resume: 'Only PDF files are accepted'
      }));
      e.target.value = ''; // Reset file input
      return;
    }

    setFormData(prev => ({
      ...prev,
      resume: file
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setCaptchaError('')
    setIsFormUnsuccessful(false)
    setIsSubmitting(true)

    // Only check captcha in production
    if (isProd) {
      const captchaToken = recaptchaRef.current?.getValue()
      if (!captchaToken) {
        setCaptchaError('Please complete the captcha')
        return
      }
      formData.captchaToken = captchaToken
    }

    // Validate form data
    try {
      await formSchema.validate(formData, { abortEarly: false })
    } catch (validationError) {
      const errors = {}
      validationError.inner.forEach((err) => {
        errors[err.path] = err.message
      })
      setFormErrors(errors)
      setIsSubmitting(false)
      return
    }

    // Create FormData object
    const formDataToSend = new FormData()
    
    // Append all text fields
    formDataToSend.append('firstName', formData.firstName)
    formDataToSend.append('lastName', formData.lastName)
    formDataToSend.append('email', formData.email)
    formDataToSend.append('phone', formData.phone || '')
    formDataToSend.append('location', formData.location)
    formDataToSend.append('linkedin', formData.linkedin || '')
    formDataToSend.append('github', formData.github || '')
    formDataToSend.append('portfolio', formData.portfolio || '')
    formDataToSend.append('salaryExpectation', formData.salaryExpectation)
    formDataToSend.append('experience', formData.experience)
    formDataToSend.append('coverLetter', formData.coverLetter)
    formDataToSend.append('jobId', id)
    
    // Append captcha token if in production
    if (isProd && formData.captchaToken) {
      formDataToSend.append('captchaToken', formData.captchaToken)
    }

    // Send data to backend
    axios.post(`${KIST_URL}/jobs/apply`, formDataToSend, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async (response) => {
        const resumeForm = new FormData();
        resumeForm.append('resume', formData.resume)
        resumeForm.append('applicationId', response.data.applicationId)

        const resumeBase64 = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(formData.resume)
          reader.onload = () => resolve(reader.result)
        });
        const chunkSize = 1000*1000;
        const totalChunks = Math.ceil(resumeBase64.length / chunkSize);
        await Promise.all(
          Array.from({ length: totalChunks }, (_, i) => {
            const resumeChunk = resumeBase64.slice(i*chunkSize, (i+1)*chunkSize);
            return axios.post(`${KIST_URL}/jobs/upload-resume/${response.data.applicationId}?chunk=${i}`, 
              {
                chunkId: i,
                resume: resumeChunk,
                totalChunks
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                },
                maxBodyLength: Infinity,
                maxContentLength: Infinity,
                responseType: 'json'
              }
            );
          })
        );

        // Convert resume to base64 only for display purposes in ApplicationViewer
        const reader = new FileReader()
        reader.readAsDataURL(formData.resume)
        const resumeString = await new Promise((resolve) => {
          reader.onload = () => resolve(reader.result)
        })

        // Store the submitted application data
        setSubmittedApplication({
          ...response.data,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          location: formData.location,
          linkedin: formData.linkedin,
          github: formData.github,
          portfolio: formData.portfolio,
          experience: formData.experience,
          coverLetter: formData.coverLetter,
          resume: resumeString // base64 for display only
        })

        // Reset form
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          location: '',
          linkedin: '',
          github: '',
          portfolio: '',
          experience: '',
          coverLetter: '',
          resume: null,
          captchaToken: '',
          salaryExpectation: '',
        })
        setFormErrors({})
        setIsFormUnsuccessful(false)
        setFormSubmitted(true)
        if (isProd && recaptchaRef.current) {
          recaptchaRef.current.reset()
        }
        setIsSubmitting(false)
        window.scrollTo(0, 0)
    }).catch((error) => {
      console.error('Error submitting application:', error)
      setFormSubmitted(false)
      setIsFormUnsuccessful(true)
      setIsSubmitting(false)
      if (isProd && recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
    });
  }

  return (
    <div className='flex flex-col items-center py-[20px] min-h-screen overflow-hidden'>
      <GlowBackground />
      <Navbar />
      
      <main className="container relative mb-[90px] md:mb-[120px]">

        <div className='relative bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mt-12'>
          <button 
            onClick={() => navigate(`/jobs`)}
            className="flex items-center gap-2 text-kaz-blue hover:text-white transition-colors duration-300 m-7 mb-0 px-4 py-2 rounded-full border-2 border-kaz-blue border-opacity-40 hover:border-opacity-100 hover:bg-kaz-blue hover:bg-opacity-25"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Back to Jobs
          </button>
          {!formSubmitted ? (
            <form onSubmit={handleSubmit} className='flex flex-col items-center w-full p-7 sm:p-14'>

              <div className="w-full mb-10">
                <h1 className="h1-large text-center mb-6">{job.title || ""}</h1>
                <div className="flex flex-wrap justify-center gap-4 mb-6">
                  <span className="bg-blue-600/50 px-3 py-1 rounded-full text-white text-sm">
                    {job.location}
                  </span>
                  <span className="bg-green-600/50 px-3 py-1 rounded-full text-white text-sm">
                    {job.type}
                  </span>
                  <span className="bg-purple-600/50 px-3 py-1 rounded-full text-white text-sm">
                    {job.department}
                  </span>
                </div>

                <div className="bg-gray-800/30 rounded-lg p-6 backdrop-blur-sm mb-6">
                  <h2 className="section-header">About the Company</h2>
                  <p className="text-gray-300 mb-6">{job.detailedDescription.companyDescription}</p>
                  <h2 className="section-header">Why join us?</h2>
                  <p className="text-gray-300 mb-6">{job.detailedDescription.whyJoin}</p>

                  <h2 className="section-header">Role Description</h2>
                  <p className="text-gray-300 mb-6">{job.detailedDescription.roleDescription}</p>

                  <div className="mb-6">
                    <h2 className="section-header">Requirements</h2>
                    <ul className="list-none space-y-2 role-list">
                      {job.requirements.map((req, index) => (
                        <li key={index} className="flex items-start text-gray-300">
                          {req}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {job.detailedDescription.technicalRequirements && (
                    <>
                      <h2 className="section-header">Technical Requirements</h2>
                      <ul className="list-none space-y-2 mb-4 role-list">
                        {job.detailedDescription.technicalRequirements.map((req, index) => (
                          <li key={index} className="flex items-start text-gray-300">
                            {req}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  <div>
                    {/* <h2 className="section-header">About the Role</h2>
                    <p className="text-gray-300 mb-4">{job.detailedDescription.roleDescription}</p> */}

                    <h2 className="section-header">Desirable Traits</h2>
                    <ul className="list-disc list-inside space-y-2 mb-4 role-list">
                      {job.detailedDescription.desirableTraits.map((trait, index) => (
                        <li key={index} className="flex items-start text-gray-300">
                          {trait}
                        </li>
                      ))}
                    </ul>

                    <h2 className="section-header">Working Conditions</h2>
                    <ul className="list-disc list-inside space-y-2 role-list">
                      {job.detailedDescription.workConditions.map((condition, index) => (
                        <li key={index} className="flex items-start text-gray-300">
                          {condition}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="w-full border-t border-kaz-blue border-opacity-40 pt-10 mb-6">
                  <h2 className="h2 text-center mb-10">Application Form</h2>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                    <FormField
                      fieldName="firstName"
                      fieldLabel="First Name"
                      value={formData.firstName}
                      handleChange={handleChange}
                      errors={formErrors.firstName}
                    />
                    <FormField
                      fieldName="lastName"
                      fieldLabel="Last Name"
                      value={formData.lastName}
                      handleChange={handleChange}
                      errors={formErrors.lastName}
                    />
                  </div>

                  <FormField
                    fieldName="email"
                    fieldLabel="Email Address"
                    value={formData.email}
                    handleChange={handleChange}
                    errors={formErrors.email}
                    type="email"
                  />

                  <FormField
                    fieldName="phone"
                    fieldLabel="Phone Number"
                    value={formData.phone}
                    handleChange={handleChange}
                    errors={formErrors.phone}
                    type="tel"
                  />

                  <FormField
                    fieldName="location"
                    fieldLabel="Current Location"
                    value={formData.location}
                    handleChange={handleChange}
                    errors={formErrors.location}
                  />

                  <FormField
                    fieldName="linkedin"
                    fieldLabel="LinkedIn Profile"
                    value={formData.linkedin}
                    handleChange={handleChange}
                    errors={formErrors.linkedin}
                    type="text"
                    required={false}
                  />

                  <FormField
                    fieldName="github"
                    fieldLabel="GitHub Profile"
                    value={formData.github}
                    handleChange={handleChange}
                    errors={formErrors.github}
                    type="text"
                    required={false}
                  />

                  <FormField
                    fieldName="portfolio"
                    fieldLabel="Portfolio Website"
                    value={formData.portfolio}
                    handleChange={handleChange}
                    errors={formErrors.portfolio}
                    type="text"
                    required={false}
                  />

                  <FormField
                    fieldName="salaryExpectation"
                    fieldLabel="Salary Expectation"
                    value={formData.salaryExpectation}
                    handleChange={handleChange}
                    errors={formErrors.salaryExpectation}
                    type="text"
                    required={false}
                  />

                  <FormField
                    fieldName="experience"
                    fieldLabel="Relevant Experience"
                    value={formData.experience}
                    handleChange={handleChange}
                    errors={formErrors.experience}
                    isTextArea={true}
                    multiline={true}
                    maxchars={500}
                    placeholder="Please describe your relevant experience..."
                  />

                  <FormField
                    fieldName="coverLetter"
                    fieldLabel="Cover Letter"
                    value={formData.coverLetter}
                    handleChange={handleChange}
                    errors={formErrors.coverLetter}
                    isTextArea={true}
                    multiline={true}
                    maxchars={1000}
                    placeholder="Tell us why you're interested in this position..."
                  />

                  <div className='flex flex-col w-full pb-6'>
                    <label className='h4 pl-6 pb-1 tracking-wide'>
                      Resume/CV (PDF, max 5MB) <span className='text-kaz-green text-[18px]'>*</span>
                    </label>
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      required
                      accept=".pdf"
                      onChange={handleFileChange}
                      className='p bg-kaz-blue bg-opacity-5 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] px-5 py-3 transition-colors duration-300 ease-out hover:bg-opacity-[0.15] focus:border-opacity-100 focus:outline-none focus:bg-opacity-[0.15]'
                    />
                    {formErrors.resume && (
                      <p className='p-small font-normal pl-6 pt-1 text-kaz-red animate-fadeIn'>{formErrors.resume}</p>
                    )}
                  </div>

                  {/* Only show captcha in production */}
                  {isProd && (
                    <div className="w-full mb-4">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={RECAPTCHA_SITE_KEY}
                        className="mt-4"
                      />
                    </div>
                  )}

                  {/* Moved captcha error message here */}
                  {captchaError && (
                    <p className="text-red-500 text-sm mb-4">{captchaError}</p>
                  )}

                  {isFormUnsuccessful && (
                    <div className='w-full mb-8 bg-red-500/10 border-2 border-red-500/20 rounded-lg p-6 backdrop-blur-sm'>
                      <h2 className='text-xl text-red-500 font-semibold mb-2'>Something Went Wrong</h2>
                      <p className='text-red-400'>Your application could not be submitted. Please try again.</p>
                    </div>
                  )}

                  {!isSubmitting ? (
                    <button 
                      type="submit" 
                      className="btn btn-primary mt-6"
                    >
                      Submit Application
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary mt-6 flex items-center justify-center"
                      disabled
                    >
                      <svg aria-hidden="true" role="status" className="inline w-[22px] h-[22px] me-3 animate-spin text-opacity-30" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                      </svg>
                      Submitting...
                    </button>
                  )}
              </div>
            </form>
          ) : (
            <div className='flex flex-col animate-fadeIn p-14'>
              <h2 className='h2 pb-5'>Application Submitted</h2>
              <p className='p mb-8'>Thank you for applying to {job.title} at Kazacos AI. We have received your application and will review it shortly.</p>
              
              {submittedApplication && (
                <ApplicationViewer application={submittedApplication} />
              )}
            </div>
          )}
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default JobApplication 