import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GlowBackground from '../components/GlowBackground'
import { useJobs } from '../contexts/JobsContext'
import { useAuth } from '../contexts/AuthContext'

function Jobs() {
  const { jobs, loading, error, fetchJobs } = useJobs()
  const { loggedIn } = useAuth()
  const fetchInitiated = useRef(false)

  useEffect(() => {
    if (!fetchInitiated.current) {
      fetchInitiated.current = true
      fetchJobs()
    }
  }, [fetchJobs])

  return (
    <div className='flex flex-col items-center py-[20px] min-h-screen overflow-hidden'>
      <GlowBackground />
      <Navbar />
      
      <main className="container relative mb-[90px] md:mb-[120px]">
        <div className='relative bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mt-12'>
          <div className='p-7 sm:p-14'>
            <div className="flex justify-between items-center mb-10">
              <h1 className="h1-large text-center flex-grow">Career Opportunities</h1>
              {!!loggedIn && (
                <Link 
                  to="/applicants"
                  className="btn btn-primary whitespace-nowrap"
                >
                  Go to Applicants
                </Link>
              )}
            </div>
            
            {loading ? (
              <div className="text-center text-white">Loading jobs...</div>
            ) : error ? (
              <div className="text-center text-red-500">{error}</div>
            ) : jobs.length === 0 ? (
              <div className="text-center text-white">No jobs available at the moment.</div>
            ) : (
              <div className="space-y-8">
                {jobs.map((job, index) => (
                  <React.Fragment key={job.id}>
                    <div className="bg-gray-800/30 rounded-lg p-6 backdrop-blur-sm">
                      <h2 className="text-2xl font-semibold text-white mb-4">{job.title}</h2>
                      <div className="flex flex-wrap gap-3 mb-4">
                        <span className="bg-blue-600/50 px-3 py-1 rounded-full text-white text-sm">
                          {job.location}
                        </span>
                        <span className="bg-green-600/50 px-3 py-1 rounded-full text-white text-sm">
                          {job.type}
                        </span>
                        <span className="bg-purple-600/50 px-3 py-1 rounded-full text-white text-sm">
                          {job.department}
                        </span>
                      </div>
                      <p className="text-gray-300 mb-4">
                        {job.description}
                      </p>
                      <div className="mb-6">
                        <h3 className="text-xl text-white mb-3">Requirements</h3>
                        <ul className="list-none space-y-2 role-list">
                          {job.requirements.map((req, index) => (
                            <li key={index} className="flex items-start text-gray-300">
                              {req}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <Link 
                        to={`/jobs/${job.id}`}
                        className="items-center text-kaz-blue hover:text-white transition-colors duration-300 px-4 py-2 rounded-full border-2 border-kaz-blue border-opacity-40 hover:border-opacity-100 hover:bg-kaz-blue hover:bg-opacity-25"
                      >
                        View Details/Apply
                      </Link>
                    </div>
                    {index < jobs.length - 1 && (
                      <div className="border-t border-kaz-blue border-opacity-40" />
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Jobs 