import React from 'react'

const TabbedInterfaceContent = ({ tabNumber, content, isActive }) => {
    
    return (
        <div className={`w-full h-full flex-col p-6 sm:p-10 text-center animate-fadeInEaseOut ${isActive ? 'flex' : 'hidden'}`}>
            <div className="flex-1 w-full h-full">
                <img src={`/images/tab${tabNumber}-image.jpg`} className='w-full h-full object-cover lg:rounded-tr-[30px]'/>
            </div>
            <p className='p mt-8'>{content}</p>
        </div>
    )
}

export default TabbedInterfaceContent
