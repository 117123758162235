import React, { useState } from 'react'

const FaqItem = ({ questionNumber, isActive, question, answer, activeQuestion, setActiveQuestion }) => {
    const questionClickHandler = (questionNumber) => {
        if(activeQuestion === questionNumber) {
            setActiveQuestion('')
        } else {
            setActiveQuestion(questionNumber)
        }
    }

    return (
        <button onClick={() => questionClickHandler(questionNumber)} className='pt-8 first:pt-0 w-full'>
            <div className='gradient-border-bottom pb-8'>
                <div className='flex items-center justify-between'>
                    <h3 className='h3 font-semibold text-left'>{question}</h3>
                    <div className='w-[15px] h-[15px] min-w-[15px] min-h-[15px] xs:w-[25px] xs:h-[25px] flex items-center justify-center'>
                        <img src={activeQuestion === questionNumber ? '/images/minus-icon.png' : '/images/plus-icon.png'} alt='Expand/Shrink Icon'/>
                    </div>
                </div>
                <p className={`p text-left pt-5 animate-fadeIn ${isActive ? 'block' : 'hidden'}`}>{answer}</p>
            </div>
        </button>
    )
}

export default FaqItem
