import React from 'react'

const ServicesTitle = () => {
    return (
        <section className="container relative my-[90px] md:my-[160px]" id="services-title">
            <div className='z-[-1] w-[350px] h-[350px] xs:w-[500px] xs:h-[450px] md:w-[450px] md:h-[600px] lg:w-[700px] lg:h-[700px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-kaz-blue bg-opacity-35 rounded-full blur-[60px] xs:blur-[80px] md:blur-[100px] lg:blur-[120px]' />
            <h1 className='h1-large text-center mb-10'>Delivering <span className='text-kaz-green'>Impactful</span> AI Products & Services</h1>
            <p className='p-large text-center max-w-[920px] mx-auto'>
                Discover how our AI solutions can supercharge your business.
                Explore our specialised products and services designed to meet diverse needs.
            </p>
        </section>
    )
}

export default ServicesTitle
