import React, { useState, useEffect } from 'react'

const ScrollToTopButton = () => {
    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
        window.addEventListener('resize', handleResize)
        handleResize()
    }, [])

    const [visible, setVisible] = useState(false)
    const [rightValue, setRightValue] = useState(0)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop
        if (scrolled > 500) {
            setVisible(true)
        }
        else {
            setVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
    }

    const handleResize = () => {
        const container = document.querySelector('.container')
        const containerRect = container.getBoundingClientRect()
        const viewportWidth = window.innerWidth
        if(viewportWidth >= 1500) {
            setRightValue(((viewportWidth - containerRect.right - 18) / 2.12) + 'px')
        } else {
            setRightValue('50%')
        }
    }

    return (
        <button className={`z-[2] fixed bottom-[28px] translate-x-1/2 1.5xl:translate-x-[initial] 1.5xl:top-1/2 1.5xl:bottom-[initial] rounded-full gradient-ellipse p-2 md:p-3 animate-fadeInEaseOut ${visible ? 'block' : 'hidden'}`} style={{ right: rightValue }} onClick={scrollToTop}>
            <img src='/images/up-icon.png' alt='Back to Top Icon' className='w-[25px] h-[25px] xs:w-[30px] xs:h-[30px] md:w-[35px] md:h-[35px]'/>
        </button>
    )
}

export default ScrollToTopButton