import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { KIST_URL } from '../util/env_defaults'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    checkAuth()
  }, [])

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  }

  const checkAuth = async () => {
    const token = getCookie('token')
    if (token) {
      try {
        const response = await axios.get(`${KIST_URL}/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        })
        setUser(response.data.user)
        setLoggedIn(true)
        setToken(token)
      } catch (error) {
        console.error('Auth check failed:', error)
        logout();
      }
    } else {
      logout();
    }
    setLoading(false)
  }

  const login = async (credentials) => {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
    const response = await axios.post(`${KIST_URL}/auth/login`, credentials, axiosConfig)
    setUser(response.data.user)
    setLoggedIn(true)
    setToken(response.data.token)
    return response.data
  }

  const logout = () => {
    document.cookie = 'token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
    setUser(null)
    setLoggedIn(false)
    setToken(null)
  }

  return (
    <AuthContext.Provider value={{ user, loading, loggedIn, token, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    alert("useAuth must be used within an AuthProvider")
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}